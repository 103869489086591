import React, { useEffect, useState } from "react";
import Grid from "@components/Grid/Grid";
import clsx from "clsx";
import { themr } from "@friendsofreactjs/react-css-themr";
import styles from "@components/__templates__/SuccessBrochurePage/SuccessBrochurePage.module.scss";
import Link from "@components/Link/Link";
import { useRouter } from "next/router";
import setGADataLayer, { dataLayerEventKey } from "@utilities/setGADataLayer";

export interface SuccessBrochureThemeProps {
  component: string;
}

export interface SuccessBrochurePageProps {
  theme: SuccessBrochureThemeProps;
  successHeaderTitleText: string;
  successDescriptionText: string;
  downloadBrochureLinkText: string;
  returnToPreviousPageLinkText: string;
}

const SuccessBrochurePage = (props: {
  content: SuccessBrochurePageProps;
  theme: SuccessBrochureThemeProps;
}) => {
  const { content, theme } = props;
  const {
    successHeaderTitleText,
    successDescriptionText,
    downloadBrochureLinkText,
    returnToPreviousPageLinkText,
  } = content;

  const router = useRouter();

  const isWindow = typeof window === "object";
  const urlParams = isWindow
    ? new URLSearchParams(window.location.search)
    : null;
  const returnToPreviousPageUrl = urlParams?.get("returnToPreviousPageUrl");
  const downloadBrochureUrl = urlParams?.get("downloadBrochureUrl");
  const programme = urlParams?.get("programme");

  const returnToOriginalPage = () => {
    router.push(`${returnToPreviousPageUrl}`, undefined, {
      shallow: true,
    });
  };

  /* Required for dataLayer only - shouldn't really be based on URL in case of any url changes
   * Future work required to add a brochure type ID to contenful and consume it here
   * - AB
   */
  const brochureType =
    isWindow &&
    window.location.href.includes("download-an-employment-report-success")
      ? dataLayerEventKey.EMPLOYMENT_REPORT_DOWNLOAD
      : dataLayerEventKey.BROCHURE_DOWNLOAD;
  /* end */

  return (
    <div className="component-wrap">
      <Grid row>
        <Grid column sm={12}>
          <div className={clsx("component", theme["success-brochure"])}>
            <div className="wrapper">
              <Grid row>
                <Grid column sm={12} md={12} lg={12}>
                  <h1 className={clsx(theme["section-title"], theme["h2"])}>
                    {successHeaderTitleText}
                  </h1>
                  <div className={theme["success-text"]}>
                    <p>{successDescriptionText}</p>
                  </div>
                </Grid>
              </Grid>
            </div>
            <div className={clsx(theme["links-wrapper"])}>
              <div className="wrapper">
                <Grid row>
                  <Grid column sm={12} md={12} lg={12}>
                    <div className={theme["userLink-section"]}>
                      {downloadBrochureUrl && (
                        <p>
                          <Link href={`${downloadBrochureUrl}`}>
                            <a
                              href={`${downloadBrochureUrl}`}
                              target="_blank"
                              rel="noreferrer"
                              onClick={() => {
                                setGADataLayer({
                                  data: {
                                    brochure_name: programme
                                      .split("/")
                                      .reverse()[0],
                                  },
                                  _clear: true,
                                  event: brochureType,
                                });
                              }}
                            >
                              {downloadBrochureLinkText}
                            </a>
                          </Link>
                        </p>
                      )}

                      {returnToPreviousPageUrl && (
                        <p>
                          <Link href={`${returnToPreviousPageUrl}`}>
                            <a onClick={returnToOriginalPage}>
                              {returnToPreviousPageLinkText}
                            </a>
                          </Link>
                        </p>
                      )}
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default themr("SuccessBrochurePage", styles)(SuccessBrochurePage);
